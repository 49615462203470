/*************** AUTH *******************/
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_SIDEBAR = 'SET_SIDEBAR';
/************** DASHBOARD  **************************/

export const CURRENT_OPENING = 'CURRENT_OPENING';
export const BRANCH_EMPLOYEE_COUNT = 'BRANCH_EMPLOYEE_COUNT';
export const EMPLOYEE_COUNT_FOR_CHART = 'EMPLOYEE_COUNT_FOR_CHART';

/************** OTHER  **************************/

export const COMPANY_INDEX = 'COMPANY_INDEX';
export const CURRENT_COMPANY = 'CURRENT_COMPANY';

export const COUNTRY_LIST = 'COUNTRY_LIST';
export const STATE_LIST = 'STATE_LIST';
/************** BRANCH  **************************/

export const BRANCH_INDEX = 'BRANCH_INDEX';
export const BRANCH_CREATE = 'BRANCH_CREATE';
export const BRANCH_CREATE_ERROR = 'BRANCH_CREATE_ERROR';

/************** DEPARTMENT  **************************/

export const DEPT_INDEX = 'DEPT_INDEX';
export const DEPT_CREATE = 'DEPT_CREATE';

/************** DESIGNATION  **************************/

export const DES_INDEX = 'DES_INDEX';
export const DES_CREATE = 'DES_CREATE';

/************** SHIFT  **************************/

export const SHIFT_INDEX = 'SHIFT_INDEX';
export const SHIFT_CREATE = 'SHIFT_CREATE';

/************** PAY  **************************/

export const PAY_INDEX = 'PAY_INDEX';
export const PAY_CREATE = 'PAY_CREATE';
export const PAY_EDIT = 'PAY_EDIT';


/************** HOLIDAY  **************************/

export const HOLIDAY_INDEX = 'HOLIDAY_INDEX';
export const HOLIDAY_INDEX_BY_BRANCH = 'HOLIDAY_INDEX_BY_BRANCH';
export const HOLIDAY_CREATE = 'HOLIDAY_CREATE';
export const HOLIDAY_EDIT = 'HOLIDAY_EDIT';

/************** Work Week  **************************/
export const WEEK_INDEX = 'WEEK_INDEX';

/************** Employee  **************************/
export const EMPL_VIEW = 'EMPL_VIEW';
export const EMPL_INDEX = 'EMPL_INDEX';

/************** Employee  **************************/
export const RECRUIT_VIEW = 'EMPL_VIEW';
export const RECRUIT_INDEX = 'RECRUIT_INDEX';
export const EMPL_BY_BRANCH = 'EMPL_BY_BRANCH';
export const EMPL_BRANCH = 'EMPL_BRANCH';

/*************** POLICY *******************/
export const GET_POLICY_BASE_DATA = 'GET_POLICY_BASE_DATA';
export const SET_POLICY_BASE_DATA = 'SET_POLICY_BASE_DATA';
export const GET_SESSION_DATA = 'GET_SESSION_DATA';
export const SET_SESSION_DATA = 'SET_SESSION_DATA';
export const SET_POLICY_FILTERED_DATA = 'SET_POLICY_FILTERED_DATA';
export const SET_POLICY_DATA = 'SET_POLICY_DATA';
export const GET_POLICY_FILTERED_DATA = 'GET_POLICY_FILTERED_DATA';
export const GET_POLICY_SEARCH_PARAMS = 'GET_POLICY_SEARCH_PARAMS';
export const SET_POLICY_SEARCH_PARAMS = 'SET_POLICY_SEARCH_PARAMS';

export const UPDATE_QUOTE_PARAM = 'UPDATE_QUOTE_PARAM';
export const LOADING = "LOADING";
export const GET_ACCOUNT_TYPES = "GET_ACCOUNT_TYPES";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_ACCOUNT_LEDGER = "GET_ACCOUNT_LEDGER";
export const GET_FILTERED_BATCH_MASTERS = 'GET_FILTERED_BATCH_MASTERS';
export const GET_INVOICES = "GET_INVOICES";
export const SET_CREDIT_DEBIT = "SET_CREDIT_DEBIT";
export const RESET_CREDIT_DEBIT = "RESET_CREDIT_DEBIT";
export const REMOVE_CREDIT_DEBIT = "REMOVE_CREDIT_DEBIT";
export const GET_BATCH_DETAILS = 'GET_BATCH_DETAILS';
export const SET_BATCH_DETAILS = 'SET_BATCH_DETAILS';
export const EDIT_BATCH_DETAILS = 'EDIT_BATCH_DETAILS';
export const GET_TRANS_SUB_TYPE = 'GET_TRANS_SUB_TYPE';
export const SEARCH_ACCOUNT = 'SEARCH_ACCOUNT';
export const GET_TAX_LINE= 'GET_TAX_LINE';

export const SAVE_NEW_BATCH = 'SAVE_NEW_BATCH';
export const SET_PARAM_FOR_ISSUED_SCR = 'SET_PARAM_FOR_ISSUED_SCR';
export const SET_POL_HEAD_DATA = 'SET_POL_HEAD_DATA';
export const SET_MAX_TRANS_DATA = 'SET_MAX_TRANS_DATA';

// export const AUTH_LOGOUT = 'PRODUCER_AGENCY_ADD';
/************** Vendors ******************/
export const GET_VENDORS = "GET_VENDORS";
export const EDIT_ROW = "EDIT_ROW";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
/********** PRODUCER_TAB Management *************/
export const PRODUCER_TAB_SELECT = 'PRODUCER_TAB_SELECT';
/********** PRODUCER_AGENCY *************/
export const PRODUCER_AGENCY_ADD = 'PRODUCER_AGENCY_ADD';
export const PRODUCER_AGENCY_UPDATE = 'PRODUCER_AGENCY_UPDATE';
export const PRODUCER_AGENCY_SAVE = 'PRODUCER_AGENCY_SAVE';
export const PRODUCER_AGENCY_SEARCH = 'PRODUCER_AGENCY_SEARCH';
export const PRODUCER_AGENCY_LOAD = 'PRODUCER_AGENCY_LOAD';
export const PRODUCER_AGENCY_SWITCH_SEARCH_MODAL = 'PRODUCER_AGENCY_SWITCH_SEARCH_MODAL';

/********** PRODUCER_AVReview *************/
export const PRODUCER_AVR_ADD = 'PRODUCER_AVR_ADD';
export const PRODUCER_AVR_UPDATE = 'PRODUCER_AVR_UPDATE';
export const PRODUCER_AVR_SAVE = 'PRODUCER_AVR_SAVE';
/********** PRODUCER_LOGS *************/
export const PRODUCER_LOGS_SHOW = 'PRODUCER_LOGS_SHOW';
export const PRODUCER_LOGS_ADD = 'PRODUCER_LOGS_ADD';
export const PRODUCER_LOGS_SAVE = 'PRODUCER_LOGS_SAVE';
/********** PRODUCER_COMMISSIONS *************/
export const PRODUCER_COMMISSIONS_SHOW = 'PRODUCER_COMMISSIONS_SHOW';
export const PRODUCER_COMM_ACC_DETAIL_SHOW = 'PRODUCER_COMM_ACC_DETAIL_SHOW';
export const PRODUCER_COMM_STMT_DETAIL_SHOW = 'PRODUCER_COMM_STMT_DETAIL_SHOW';
/********** PRODUCER_DOCUMENTS *************/
export const PRODUCER_DOCUMENTS_SHOW = 'PRODUCER_DOCUMENTS_SHOW';
export const PRODUCER_DOCUMENTS_SAVE = 'PRODUCER_DOCUMENTS_SAVE';
export const PRODUCER_DOCUMENTS_DELETE = 'PRODUCER_DOCUMENTS_DELETE';
/********** PRODUCER_AGENCY_USER *************/
export const PRODUCER_AUSERS_SHOW = 'PRODUCER_AUSERS_SHOW';
export const PRODUCER_AUSERS_DETAIL_SHOW = 'PRODUCER_AUSERS_DETAIL_SHOW';
/************* Client Management ***************/
export const SEARCH_CLIENT = 'SEARCH_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const SAVE_ADDR = 'SAVE_ADDR';
export const SAVE_CLIENT_FORM = 'SAVE_CLIENT_FORM';
export const SAVE_ROLE = 'SAVE_ROLE';
export const SET_CLIENTPK = 'SET_CLIENTPK';

/**
 * ADMINISTRATION MODULE
 */
export const LEFTNAV_FLAG = 'LEFTNAV_FLAG';
export const GET_FILTERED_LEVEL_DATA = 'GET_FILTERED_LEVEL_DATA';
export const LOADING_TRUE = "LOADING_TRUE";
export const LOADING_FALSE = "LOADING_FALSE";
export const LOADING_TRUE_ADDBTN = "LOADING_TRUE_ADDBTN";
export const LOADING_FALSE_ADDBTN = "LOADING_FALSE_ADDBTN";
export const LOADING_TRUEFALSE_LEVELADD = "LOADING_TRUEFALSE_LEVELADD";
export const LOADING_TRUEFALSE_LEVELLIST = "LOADING_TRUEFALSE_LEVELLIST";
export const POPULATE_LEVEL_ADDEDITFORM = "POPULATE_LEVEL_ADDEDITFORM";
export const RESET_LEVEL_ADDEDITFORM = "RESET_LEVEL_ADDEDITFORM";
export const RELOAD_LEVELMNG_LEVELLIST = "RELOAD_LEVELMNG_LEVELLIST";

export const GET_FILTERED_LEVEL_DATA_MNGMODULE = 'GET_FILTERED_LEVEL_DATA_MNGMODULE';
export const LOADING_TRUE_MNGMODULE = "LOADING_TRUE_MNGMODULE";
export const LOADING_FALSE_MNGMODULE = "LOADING_FALSE_MNGMODULE";
export const LOADING_TRUE_ADDBTN_MNGMODULE = "LOADING_TRUE_ADDBTN_MNGMODULE";
export const LOADING_FALSE_ADDBTN_MNGMODULE = "LOADING_FALSE_ADDBTN_MNGMODULE";
export const LOADING_TRUEFALSE_MODULEADD = "LOADING_TRUEFALSE_MODULEADD";
export const LOADING_TRUEFALSE_MODULELIST = "LOADING_TRUEFALSE_MODULELIST";
export const CLEAR_SEARCHFIELD_DATATABLE = "CLEAR_SEARCHFIELD_DATATABLE";
export const POPULATE_MODULE_ADDEDITFORM = "POPULATE_MODULE_ADDEDITFORM";
export const RESET_MODULE_ADDEDITFORM = "RESET_MODULE_ADDEDITFORM";
export const RELOAD_MODULEMNG_MODULELIST = "RELOAD_MODULEMNG_MODULELIST";


export const GET_FILTERED_LEVEL_DATA_MNGSUBMODULE = 'GET_FILTERED_LEVEL_DATA_MNGSUBMODULE';
export const LOADING_TRUE_MNGSUBMODULE = "LOADING_TRUE_MNGSUBMODULE";
export const LOADING_FALSE_MNGSUBMODULE = "LOADING_FALSE_MNGSUBMODULE";
export const LOADING_TRUE_ADDBTN_MNGSUBMODULE = "LOADING_TRUE_ADDBTN_MNGSUBMODULE";
export const LOADING_FALSE_ADDBTN_MNGSUBMODULE = "LOADING_FALSE_ADDBTN_MNGSUBMODULE";
export const LOADING_TRUEFALSE_SUBMODULEADD = "LOADING_TRUEFALSE_SUBMODULEADD";
export const LOADING_TRUEFALSE_SUBMODULELIST = "LOADING_TRUEFALSE_SUBMODULELIST";
export const LOADING_TRUEFALSE_SUBMODULE_MODULEDD = "LOADING_TRUEFALSE_SUBMODULE_MODULEDD";
export const POPULATE_SUBMODULE_MODULEDD = "POPULATE_SUBMODULE_MODULEDD";
export const LOADING_TRUEFALSE_SUBMODULE_DOCTYPEDD = "LOADING_TRUEFALSE_SUBMODULE_DOCTYPEDD";
export const POPULATE_SUBMODULE_DOCTYPEDD = "POPULATE_SUBMODULE_DOCTYPEDD";
export const POPULATE_SUBMODULE_ADDEDITFORM = "POPULATE_SUBMODULE_ADDEDITFORM";
export const RESET_SUBMODULE_ADDEDITFORM = "RESET_SUBMODULE_ADDEDITFORM";
export const RELOAD_SUBMODULEMNG_SUBMODULELIST = "RELOAD_SUBMODULEMNG_SUBMODULELIST";

export const LOADING_TRUEFALSE_ACLMNG_LEVELDD = "LOADING_TRUEFALSE_ACLMNG_LEVELDD";
export const POPULATE_ACLMNG_LEVELDD = "POPULATE_ACLMNG_LEVELDD";
export const POPULATE_ACLMNG_LEVELDDSELECTED = "POPULATE_ACLMNG_LEVELDDSELECTED";
export const LOADING_TRUEFALSE_ACLMNG_APPLIEDLIST = "LOADING_TRUEFALSE_ACLMNG_APPLIEDLIST";
export const POPULATE_ACLMNG_APPLIEDLIST = "POPULATE_ACLMNG_APPLIEDLIST";
export const RELOAD_ACLMNG_APPLIEDLIST = "RELOAD_ACLMNG_APPLIEDLIST";
export const LOADING_TRUEFALSE_ACLMNG_DENIEDLIST = "LOADING_TRUEFALSE_ACLMNG_DENIEDLIST";
export const POPULATE_ACLMNG_DENIEDLIST = "POPULATE_ACLMNG_DENIEDLIST";
export const RELOAD_ACLMNG_DENIEDLIST = "RELOAD_ACLMNG_DENIEDLIST";
export const SET_ACLMNG_MODULE_ID = "SET_ACLMNG_MODULE_ID";
export const LOADING_TRUEFALSE_ACLMNG_SUBMODULELIST = "LOADING_TRUEFALSE_ACLMNG_SUBMODULELIST";
export const POPULATE_ACLMNG_SUBMODULELIST = "POPULATE_ACLMNG_SUBMODULELIST";

export const FETCH_GMAPRULE = "FETCH_GMAPRULE";
export const SAVE_GMAPZONE = "SAVE_GMAPZONE";
export const FETCH_MAPZONEDATA = "FETCH_MAPZONEDATA";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const SAVE_GROUP = "SAVE_GROUP";
export const FETCH_GROUPS_LISTING = "FETCH_GROUPS_LISTING";
export const SAVE_POLICY_VALIDATION_RULE = "SAVE_POLICY_VALIDATION_RULE";
export const FETCH_INNER_POLICY_RULE = "FETCH_INNER_POLICY_RULE";

export const FETCH_VALIDATION_RULES_LISTING = "FETCH_VALIDATION_RULES_LISTING";
export const FETCH_VALIDATION_FORM_OPTIONS = "FETCH_VALIDATION_FORM_OPTIONS";
export const FETCH_POLICY_RULE_FOR_OPTIONS = "FETCH_POLICY_RULE_FOR_OPTIONS";
export const FETCH_POLICY_RULE_FOR_DATA = "FETCH_POLICY_RULE_FOR_DATA";

export const FETCH_VALIDATION_GET_PRODUCT_DD = "FETCH_VALIDATION_GET_PRODUCT_DD";
export const FETCH_VALIDATION_RULE_LOAD = "FETCH_VALIDATION_RULE_LOAD";
export const FETCH_VALIDATION_RULE_LOAD_COMBO = "FETCH_VALIDATION_RULE_LOAD_COMBO";
export const SAVE_POLICY_RULE_GROUP = "SAVE_POLICY_RULE_GROUP";
export const FETCH_VALIDATION_RULE_GROUP_LISTING = "FETCH_VALIDATION_RULE_GROUP_LISTING";

/*ADMINISTRATION=>DOCUMENT HANDLER */
export const LL_TF_DOCHANDLER_PRODUCT_DT = "LL_TF_DOCHANDLER_PRODUCT_DT";
export const POPULATE_DOCHANDLER_PRODUCT_DT = "POPULATE_DOCHANDLER_PRODUCT_DT";
export const GET_DOCHANDLER_SELECTED_PRODUCT_ID = "GET_DOCHANDLER_SELECTED_PRODUCT_ID";
export const LL_TF_DOCHANDLER_DOCTYPE_DT = "LL_TF_DOCHANDLER_DOCTYPE_DT";
export const POPULATE_DOCHANDLER_DOCTYPE_DT = "POPULATE_DOCHANDLER_DOCTYPE_DT";
export const LL_TF_DOCHANDLER_DOCTYPE_DD = "LL_TF_DOCHANDLER_DOCTYPE_DD";
export const POPULATE_DOCHANDLER_DOCTYPE_DD = "POPULATE_DOCHANDLER_DOCTYPE_DD";
export const GET_DOCHANDLER_SELECTED_DOCTYPE_ID = "GET_DOCHANDLER_SELECTED_DOCTYPE_ID";
export const GET_DOCHANDLER_SELECTED_DOCTYPE_ID_SELECTBOX = "GET_DOCHANDLER_SELECTED_DOCTYPE_ID_SELECTBOX";
export const GET_DOCHANDLER_SELECTED_MODULE_ID_SELECTBOX = "GET_DOCHANDLER_SELECTED_MODULE_ID_SELECTBOX";
export const LL_TF_DOCHANDLER_FORMNAME_DT = "LL_TF_DOCHANDLER_FORMNAME_DT";
export const POPULATE_DOCHANDLER_FORMNAME_DT = "POPULATE_DOCHANDLER_FORMNAME_DT";
export const GET_DOCHANDLER_SELECTED_FORMNAME_ID = "GET_DOCHANDLER_SELECTED_FORMNAME_ID";
export const LL_TF_DOCHANDLER_DOCUMENT_DT = "LL_TF_DOCHANDLER_DOCUMENT_DT";
export const POPULATE_DOCHANDLER_DOCUMENT_DT = "POPULATE_DOCHANDLER_DOCUMENT_DT";
export const GET_DOCHANDLER_SELECTED_DOCUMENT_ID = "GET_DOCHANDLER_SELECTED_DOCUMENT_ID";
//--
export const TF_DOCHANDLER_ADDNEWDOC_MODEL = "TF_DOCHANDLER_ADDNEWDOC_MODEL";
export const LL_TF_DOCHANDLER_DOCUMENTADDNEW_DT = "LL_TF_DOCHANDLER_DOCUMENTADDNEW_DT";
export const POPULATE_DOCHANDLER_DOCUMENTADDNEW_DT = "POPULATE_DOCHANDLER_DOCUMENTADDNEW_DT";
export const GET_DOCHANDLER_SELECTED_DOCUMENTADDNEW_ID = "GET_DOCHANDLER_SELECTED_DOCUMENTADDNEW_ID";
export const DOEMPTY_DOCHANDLER_SELECTED_ITEMROW_ID = "DOEMPTY_DOCHANDLER_SELECTED_ITEMROW_ID";
export const DO_FORCEFULLY_UPDATE = "DO_FORCEFULLY_UPDATE";












/******************** BANKING ********************/
export const GET_BANKING_LIST = 'GET_BANKING_LIST';
export const GET_RECONCILE_BANK = 'GET_RECONCILE_BANK';
export const INC_CHUNK_SIZE = "INC_CHUNK_SIZE";
/******************** Territory ********************/
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_STATE = "GET_STATE";
export const GET_COUNTY = "GET_COUNTY";
export const GET_CITY = "GET_CITY";
export const GET_ZIP_CODE = "GET_ZIP_CODE";
export const SEARCH_ID = "SEARCH_ID";





/***************** Commission Action Types ****************/
export const GET_FILTERED_TERRITORY_DATA_MNGMODULE = 'GET_FILTERED_TERRITORY_DATA_MNGMODULE';
export const GET_FILTERED_TERRITORY_INDIVIDUAL_DATA = 'GET_FILTERED_TERRITORY_INDIVIDUAL_DATA';
export const GET_FILTERED_TERRITORY_COUNTRY_DATA = 'GET_FILTERED_TERRITORY_COUNTRY_DATA';
export const GET_FILTERED_TERRITORY_STATE_DATA = 'GET_FILTERED_TERRITORY_STATE_DATA';
export const GET_FILTERED_TERRITORY_COUNTY_DATA = 'GET_FILTERED_TERRITORY_COUNTY_DATA';
export const GET_FILTERED_TERRITORY_ZIPCODE_DATA = 'GET_FILTERED_TERRITORY_ZIPCODE_DATA';
export const LOADING_TRUE_TERRITORYMODULE = "LOADING_TRUE_TERRITORYMODULE";
export const LOADING_FALSE_TERRITORYMODULE = "LOADING_FALSE_TERRITORYMODULE";
export const LOADING_TRUE_ADDBTN_TERRITORYMODULE = "LOADING_TRUE_ADDBTN_TERRITORYMODULE";
export const LOADING_FALSE_ADDBTN_TERRITORYMODULE = "LOADING_FALSE_ADDBTN_TERRITORYMODULE";
export const LOADING_TRUE_TERRITORY_MAP = "LOADING_TRUE_TERRITORY_MAP";
export const LOADING_FALSE_TERRITORY_MAP = "LOADING_FALSE_TERRITORY_MAP";
export const LOADING_TRUE_TERRITORY_INDIVIDUAL = "LOADING_TRUE_TERRITORY_INDIVIDUAL";
export const LOADING_FALSE_TERRITORY_INDIVIDUAL = "LOADING_FALSE_TERRITORY_INDIVIDUAL";

export const GET_FILTERED_RULES_DATA = 'GET_FILTERED_RULES_DATA';
export const GET_FILTERED_RULE = 'GET_FILTERED_RULE';
export const GET_FILTERED_RULE_OPTIONS = 'GET_FILTERED_RULE_OPTIONS';
export const GET_FILTERED_RULE_PRODUCTS = 'GET_FILTERED_RULE_PRODUCTS';
export const LOADING_TRUE_RULES = "LOADING_TRUE_RULES";
export const LOADING_FALSE_RULES = "LOADING_FALSE_RULES";
export const LOADING_TRUE_ADDBTN_RULES = "LOADING_TRUE_ADDBTN_RULES";
export const LOADING_FALSE_ADDBTN_RULES = "LOADING_FALSE_ADDBTN_RULES";
export const LOADING_TRUE_RULE_OPTIONS = "LOADING_TRUE_RULE_OPTIONS";
export const LOADING_FALSE_RULE_OPTIONS = "LOADING_FALSE_RULE_OPTIONS";
export const LOADING_TRUE_RULE = "LOADING_TRUE_RULE";
export const LOADING_FALSE_RULE = "LOADING_FALSE_RULE";

export const GET_FILTERED_GROUPS_DATA = 'GET_FILTERED_GROUPS_DATA';
export const GET_FILTERED_GROUP = 'GET_FILTERED_GROUP';
export const GET_FILTERED_GROUP_PRODUCTS = 'GET_FILTERED_GROUP_PRODUCTS';
export const GET_FILTERED_GROUP_PRODUCT_RULES = 'GET_FILTERED_GROUP_PRODUCT_RULES';
export const LOADING_TRUE_GROUPS = "LOADING_TRUE_GROUPS";
export const LOADING_FALSE_GROUPS = "LOADING_FALSE_GROUPS";
export const LOADING_TRUE_ADDBTN_GROUPS = "LOADING_TRUE_ADDBTN_GROUPS";
export const LOADING_FALSE_ADDBTN_GROUPS = "LOADING_FALSE_ADDBTN_GROUPS";
export const LOADING_TRUE_GROUP_OPTIONS = "LOADING_TRUE_GROUP_OPTIONS";
export const LOADING_FALSE_GROUP_OPTIONS = "LOADING_FALSE_GROUP_OPTIONS";
export const LOADING_TRUE_GROUP = "LOADING_TRUE_GROUP";
export const LOADING_FALSE_GROUP = "LOADING_FALSE_GROUP";

export const LOADING_PRODUCTS = "LOADING_PRODUCTS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_COMMISSION_GENERATE = "GET_COMMISSION_GENERATE";
export const LOADING_COMMISSION_GENERATE = "LOADING_COMMISSION_GENERATE";
export const POSTING_COMMISSION = "POSTING_COMMISSION";
export const GET_COMMISSION_DETAILS = "GET_COMMISSION_DETAILS";
export const LOADING_COMMISSION_DETAILS = "LOADING_COMMISSION_DETAILS";

/***************** PaymentPlan Action Types ****************/
export const LOADING_PAYMENTPLANS = "LOADING_PAYMENTPLANS";
export const GET_PAYMENTPLANS = "GET_PAYMENTPLANS";
export const LOADING_PAYMENTPLAN_SCHEDULES = "LOADING_PAYMENTPLAN_SCHEDULES";
export const GET_PAYMENTPLAN_SCHEDULES = "GET_PAYMENTPLAN_SCHEDULES";
export const UPDATING_PAYMENTPLAN_SCHEDULE = "UPDATING_PAYMENTPLAN_SCHEDULE";

/********************* Common ****************/
export const SET_ASIDE_COMPONENT = 'SET_ASIDE_COMPONENT';

/********************* Investment ****************/
export const GET_INVESTMENT = 'GET_INVESTMENT';
export const GET_SECURITY_DATA = 'GET_SECURITY_DATA';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_BROKERS = 'GET_BROKERS';
export const GET_COUPONS = 'GET_COUPONS';
export const GET_PRICES = 'GET_PRICES';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_BROKERAGE_ACCOUNT = 'GET_BROKERAGE_ACCOUNT';
export const GET_MONTHLY_POSTING = 'GET_MONTHLY_POSTING';
export const GET_EARNED_POSTING = 'GET_EARNED_POSTING';
export const GET_HISTORICAL_POSTING = 'GET_HISTORICAL_POSTING';
export const GET_TRANSFER_SECURITY = 'GET_TRANSFER_SECURITY';

/********************* Quote Entry ****************/
export const IS_LOADING = 'IS_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const GET_QUOTE_BASE_DD = 'GET_QUOTE_BASE_DD';

export const GET_AGENCY_DROPDOWN = 'GET_AGENCY_DROPDOWN';
export const GET_AGENT_DROPDOWN = 'GET_AGENT_DROPDOWN';
export const GET_NEW_AGENT_DROPDOWN = 'GET_NEW_AGENT_DROPDOWN';
export const GET_AGENCY_CODE = 'GET_AGENCY_CODE';
export const GET_SEARCH_POLICY = 'GET_SEARCH_POLICY';
export const GET_REASON = 'GET_REASON';

export const TRANSFER_BOOK = 'TRANSFER_BOOK';

export const GET_SYSTEM_DROPDOWN = 'GET_SYSTEM_DROPDOWN';
export const GET_SYSTEM_DROPDOWN_LIST = 'GET_SYSTEM_DROPDOWN_LIST';
export const SYSTEM_DROPDOWN_SAVE = 'SYSTEM_DROPDOWN_SAVE';
export const UPDATE_DROPDOWN_OPTION = 'UPDATE_DROPDOWN_OPTION';

/************* Claim Management ***************/
export const ADD_EDIT_CLAIM = 'ADD_EDIT_CLAIM';
export const GET_MASTER_VALUES_CLAIM_DD = "GET_MASTER_VALUES_CLAIM_DD";
export const GET_SUB_STATUS_DD = "GET_SUB_STATUS_DD";
export const GET_REVIEW_SUB_STATUS_DD = "GET_REVIEW_SUB_STATUS_DD";
export const SET_CLAIM_DETAIL_FOR_EDIT = "SET_CLAIM_DETAIL_FOR_EDIT";
export const GET_ADJUSTER_DATA_FOR_MAP = "GET_ADJUSTER_DATA_FOR_MAP";
export const LOADING_SUB_STATUS = "LOADING_SUB_STATUS";
export const LOADING_REVIEW_SUB_STATUS = "LOADING_REVIEW_SUB_STATUS";
export const LOADING_CLAIM_MANAGER = "LOADING_CLAIM_MANAGER";

/********************* Claim Event (Administration) ****************/
export const GET_CLAIM_EVENT_DATA = 'GET_CLAIM_EVENT_DATA';
export const GET_CLAIM_EVENT_DROPDOWN = 'GET_CLAIM_EVENT_DROPDOWN';
export const GET_CLAIM_EVENT_SAVE = 'GET_CLAIM_EVENT_SAVE';

/********************* Renewal Notice (Accounting) ****************/
export const GET_RENEWAL_NOTICE_DATA = 'GET_RENEWAL_NOTICE_DATA';
export const PRINT_RENEWAL_NOTICE = 'PRINT_RENEWAL_NOTICE';

/********************* CHECK SUB MODULE ACCESS ********************/
export const CHECK_PERMISSION = 'CHECK_PERMISSION';
export const CHECK_PERMISSION_CLEAR = 'CHECK_PERMISSION_CLEAR';

/********************* Accounting View Subledger ********************/
export const LOADING_TRUEFALSE_SUBLEDGERLIST = 'LOADING_TRUEFALSE_SUBLEDGERLIST';
export const GET_FILTERED_SUB_LEDGER_DATA = 'GET_FILTERED_SUB_LEDGER_DATA';

export const LOADING_TRUEFALSE_SUBACCOUNTING = 'LOADING_TRUEFALSE_SUBACCOUNTING';
export const GET_FILTERED_SUB_ACCOUNTING_DATA = 'GET_FILTERED_SUB_ACCOUNTING_DATA';

/********************* Leave Management ********************/
export const LEAVE_TYPE_VIEW = 'LEAVE_TYPE_VIEW';
export const ADD_LEAVE_TYPE = 'ADD_LEAVE_TYPE';
export const ACTIVE_LEAVE_TYPE = 'ACTIVE_LEAVE_TYPE';

export const LEAVE_REQUEST_VIEW = 'LEAVE_REQUEST_VIEW';
export const APPROVED_LEAVES = 'APPROVED_LEAVES';
export const REJECTED_LEAVES = 'REJECTED_LEAVES';

export const LEAVE_DETAILS = 'LEAVE_DETAILS';
/********************* Leave Management ********************/

/********************* Report Managers ********************/
export const REPORT_MANAGERS = 'REPORT_MANAGERS';
/********************* Leave Managers ********************/


/********************* Salary Management ********************/
export const SALARY_HEAD_BY_BRANCH = 'SALARY_HEAD_BY_BRANCH';
export const PAY_GRADES_BY_BRANCH = "PAY_GRADES_BY_BRANCH"
/********************* Salary Management ********************/



/********************* SKILL Management ********************/
export const SKILL_VIEW = 'SKILL_VIEW';
/********************* SKILL Management ********************/


/********************* QUALIFICATION Management ********************/
export const QUALIFICATION_VIEW = 'QUALIFICATION_VIEW';
/********************* QUALIFICATION Management ********************/



/********************* EDUCATIONALLEVEL Management ********************/
export const EDUCATIONALLEVEL_VIEW = 'EDUCATIONALLEVEL_VIEW';
/********************* EDUCATIONALLEVEL Management ********************/


/********************* LANGUAGES Management ********************/
export const LANGUAGES_VIEW = 'LANGUAGES_VIEW';
/********************* LANGUAGES Management ********************/


/********************* LANGUAGES Management ********************/
export const Country_VIEW = 'Country_VIEW';
/********************* LANGUAGES Management ********************/


/********************* Quote Management ********************/
export const Quote_VIEW = 'Quote_VIEW';
/********************* Quote Management ********************/


/********************* Quote Management ********************/
export const EmploymentStatus_VIEW = 'EmploymentStatus_VIEW';
/********************* Quote Management ********************/

/********************* FinancialYears Management ********************/
export const FinancialYears_VIEW = 'FinancialYears_VIEW';
/********************* FinancialYears Management ********************/


